exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-automobile-solution-tsx": () => import("./../../../src/pages/automobile-solution.tsx" /* webpackChunkName: "component---src-pages-automobile-solution-tsx" */),
  "component---src-pages-category-tsx": () => import("./../../../src/pages/category.tsx" /* webpackChunkName: "component---src-pages-category-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-electronic-solution-tsx": () => import("./../../../src/pages/electronic-solution.tsx" /* webpackChunkName: "component---src-pages-electronic-solution-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-packaging-solution-tsx": () => import("./../../../src/pages/packaging-solution.tsx" /* webpackChunkName: "component---src-pages-packaging-solution-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-templates-solution-tsx": () => import("./../../../src/templates/solution.tsx" /* webpackChunkName: "component---src-templates-solution-tsx" */),
  "component---src-templates-solutions-list-tsx": () => import("./../../../src/templates/solutions-list.tsx" /* webpackChunkName: "component---src-templates-solutions-list-tsx" */)
}

